import React from "react";
import { Link } from "react-router-dom";
import { MenuList } from "../helpers/MenuList";
import OldNewsItem from "../components/OldNewsItem";
import "../styles/OldNews.css";
// import {fs} from 'fs';


function Menu() {



  return (
    <div className="oldNews" id="oldNews">
      <h1 className="oldNewsTitle">News</h1>
      <div className="oldNewsList">
        {MenuList.map((menuItem, key) => {
          return (
            <OldNewsItem
              key={key}
              linkTo={menuItem.linkTo}
              image={menuItem.image}
              name={menuItem.name}
              description={menuItem.description}
              // price={menuItem.price}
            />
          );
        })}
      </div>
      <Link to="/oldnews" onClick={() => window.scrollTo(0,0)}> Old News </Link>
      {/* <button onClick={() => window.scrollTo(0,0)}> Old News </button> */}
    </div>
  );
}

export default Menu;
