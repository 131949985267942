import React, { useState, useEffect } from "react";
import Logo from "../assets/pizzaLogo.png";
import PizzaLeft from "../assets/gradient.jpeg";
// import { Link } from "react-router-dom";
import {HashLink as Link} from 'react-router-hash-link';
import ReorderIcon from "@material-ui/icons/Reorder";
import CloseIcon from '@mui/icons-material/Close';
import "../styles/Navbar.css";

function Navbar() {

  const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') { 
      if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShow(false); 
      } else { // if scroll up show the navbar
        setShow(true);  
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY); 
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);


  const [openLinks, setOpenLinks] = useState(false);

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
  return (

    <div className={`navbar active ${show && 'hidden'}`}>
      <div className="leftSide" id={openLinks ? "open" : "close"}>
        <img src={Logo} />
        <div className="hiddenLinks"
          style={{ backgroundImage: `url(${PizzaLeft})` }}>
          <button onClick={toggleNavbar}>
            <CloseIcon />
          </button>
          <Link onClick={toggleNavbar} smooth to="#menu"> <h1>News</h1> </Link>
          <Link onClick={toggleNavbar} to="/gallery"> <h1>Gallery</h1> </Link>
          <Link onClick={toggleNavbar} smooth to="#about"> <h1>About</h1> </Link>
          <Link onClick={toggleNavbar} smooth to="#contact"> <h1>Contact</h1> </Link>
        </div>
      </div>
      <div className="rightSide" id={openLinks ? "close" : "open"}>
        <Link smooth to="#menu"> News </Link>
        <Link to="/gallery"> Gallery </Link>
        <Link smooth to="#about"> About </Link>
        <Link smooth to="#contact"> Contact </Link>
        <button onClick={toggleNavbar}>
          <ReorderIcon />
        </button>
      </div>
    </div>
  );
}

export default Navbar;
