import React, { useRef } from "react";
import PizzaLeft from "../assets/me.jpg";
import "../styles/Contact.css";
import emailjs from '@emailjs/browser';

function Contact() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_sq8xu2h', 'template_92cvxtd', form.current, '2KFI31G5jr5e2CLS1')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };

  return (
    <div className="contact" id="contact">
      <div className="top"
      style={{ backgroundImage: `url(${PizzaLeft})` }}
      >
       <h1 style={{color:"white"}}> About More Things / Bio / Contact </h1> 
      </div>

      <div className="flex">

        <div
          className="leftSide"
          // style={{ backgroundImage: `url(${PizzaLeft})` }}
        >
          <div style={{ backgroundImage: `url(${PizzaLeft})`, backgroundPosition:"center", width:"100px",height:"100px" }}></div>
          <div>BIO:

Jesse Wiedel was raised in a rural community outside of the central valley town of Redding, California.  After spending his young life gaining inspiration from the cultural wastelands, he eventually migrated to the city and received his degree in painting at the San Francisco Art Institute. He has spent the last twenty-five years making art in Humboldt County, California—fertile ground for his interest in human folly and frailties.


Jesse’s paintings can be read as satire, social criticism, or psychological horror.   He works mainly in oil paint, in a style that is both illusionistic and painterly. Jesse has also performed in many garage/punk bands since 1983, a practice that has also informed his artworks.</div>
        
        </div>
          <div className="rightSide">
            <h1> Contact Jesse</h1>

            <form ref={form} onSubmit={sendEmail}>
              <label htmlFor="name">Full Name</label>
              <input name="name" placeholder="Enter full name..." type="text" />
              <label htmlFor="email">Email</label>
              <input name="email" placeholder="Enter email..." type="email" />
              <label htmlFor="message">Message</label>
              <textarea
                rows="6"
                placeholder="Enter message..."
                name="message"
                required
                ></textarea>
              <button type="submit" value="send message"> Send Message</button>
            </form>
          </div>
      </div>
    </div>
  );
}

export default Contact;
