import React from "react";
import { Link } from "react-router-dom";
// import { GalleryList2022 } from "../helpers/GalleryListItem/2022";
// import { GalleryList2021 } from "../../helpers/GalleryListItem/2021";
// import { GalleryList2020 } from "../helpers/GalleryListItem/2020";
// import { GalleryList2019 } from "../helpers/GalleryListItem/2019";
// import { GalleryList2018 } from "../helpers/GalleryListItem/2018";
// import { GalleryList2017 } from "../helpers/GalleryListItem/2017";
// import { GalleryList2016 } from "../helpers/GalleryListItem/2016";
// import { GalleryList2015 } from "../helpers/GalleryListItem/2015";
// import { GalleryList2014 } from "../helpers/GalleryListItem/2014";
// import { GalleryList2013 } from "../helpers/GalleryListItem/2013";
// import { GalleryList2012 } from "../helpers/GalleryListItem/2012";
import { GalleryList2011 } from "../../helpers/GalleryListItem/2011";
// import { GalleryList2010 } from "../helpers/GalleryListItem/2010";
// import { GalleryList2009 } from "../helpers/GalleryListItem/2009";
// import { GalleryList2008 } from "../helpers/GalleryListItem/2008";
// import { GalleryList2007 } from "../helpers/GalleryListItem/2007";
import OldNewsItem from "../OldNewsItem";
import "../../styles/OldNews.css";

function GalleryPage2011() {
  
  return (
    <div className="oldNews" id="oldNews">
                <div>
          <Link to="/"> Jesse Wiedel </Link>
          </div>
      <h1 className="oldNewsTitle"> 2011 </h1>
      <div className="oldNewsList">
        {GalleryList2011.map((oldNewsItem, key) => {
          return (
            <OldNewsItem
              key={key}
              linkTo={oldNewsItem.linkTo}
              image={oldNewsItem.image}
              name={oldNewsItem.name}
              description={oldNewsItem.description}
              // price={menuItem.price}
            />
          );
        })}
      </div>
      <Link to="/2010" onClick={() => window.scrollTo(0,0)}> Next Gallery </Link>
      <Link to="/2012" onClick={() => window.scrollTo(0,0)}> Previous Gallery </Link>
    </div>
  );
}

export default GalleryPage2011;
