import React from "react";
import MultiplePizzas from "../assets/fishphoto.jpg";
import "../styles/About.css";
import Data from "../helpers/ImageCarousel"
import Slider from "../components/ImageCarousel";


function About() {
  return (
    <div 
    // style={{ backgroundImage: `url(${MultiplePizzas})` }} 
    className="about" id="about">
      <div className="aboutTop">
        
      </div>
      <div className="aboutCenter">

        <div className="aboutLeft">
        <Slider></Slider>
        </div>
        <div className="aboutRight">

          <h1> STATEMENT ON ART: </h1>
          <p>
          My narrative paintings illustrate elements of American society’s 
          psychic decline with familiar and yet uncanny scenarios that can
          be alternately grotesque, comedic, and horrifying. I combine 
          commonplace and occasionally bleak landscapes with vividly rendered images inspired by biker parties, illustrated religious tracts, counter-cultural rituals, and celebratory scenes from everyday life.  My paintings aim to elicit an uneasy sensation that our own reality is not too dissimilar from these disturbing fictional worlds. 
            
          </p>

        </div>
      </div>
    </div>
  );
}

export default About;
