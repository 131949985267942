import React from "react";
import { Link } from "react-router-dom";
import { OldNewsList } from "../helpers/OldNewsList";
import OldNewsItem from "../components/OldNewsItem";
import "../styles/OldNews.css";

function OldNews() {
  return (
    <div className="oldNews" id="oldNews">
      <h1 className="oldNewsTitle">Old News</h1>
      <div className="oldNewsList">
        {OldNewsList.map((oldNewsItem, key) => {
          return (
            <OldNewsItem
              key={key}
              linkTo={oldNewsItem.linkTo}
              image={oldNewsItem.image}
              name={oldNewsItem.name}
              description={oldNewsItem.description}
              // price={menuItem.price}
            />
          );
        })}
      </div>
      <button onClick={() => window.scrollTo(0,0)}> Top </button>
    </div>
  );
}

export default OldNews;
