import React, { Component } from "react";
import { Link } from "react-router-dom";

function OldNewsItem({ image, name, description,linkTo, price }) {
  return (
    <div className="oldNewsItem">

        {/* <picture> */}
          {/* <source media="(min-width: 1024px)" src={`url(${image})`}/> */}
      {/* <div className="frame"> */}
        {/* <a href={linkTo}> */}
        {/* <Link to={linkTo}> */}
          <img className="oldNewsImg" src={image} />
        {/* </Link> */}
        {/* </a>  */} 
        {/* <p> ${price} </p> */}
      {/* </div> */}
        {/* </picture> */}
          <p> {description} </p>


          {/* <h1> {name} </h1> */}
    </div>
  );
}

export default OldNewsItem;