import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "../styles/Footer.css";
// import Pizza from "../assets/meetupIcon2.svg";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        {/* <a href="https://www.meetup.com/Redding-Bitcoin-Meetup/">
          <img src={Pizza} 
          ></img>
        </a> */}

        <a href="https://twitter.com/BitcoinRedding">
          <InstagramIcon /> 
        </a>
        <a href="https://twitter.com/BitcoinRedding">
          <TwitterIcon /> 
        </a>
        <FacebookIcon />
      </div>
      <p> &copy; A Company of Redding, CA </p>
    </div>
  );
}

export default Footer;

// import React from "react";
// import InstagramIcon from "@material-ui/icons/Instagram";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import "../styles/Footer.css";

// function Footer() {
//   return (
//     <div className="footer">
//       <div className="socialMedia">
//         {/* <InstagramIcon /> <TwitterIcon /> <FacebookIcon /> */}
//       </div>
//       <p> &copy; A Company of Redding, CA Since 2020</p>
//     </div>
//   );
// }

// export default Footer;


