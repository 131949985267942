import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import OldNews from "./pages/OldNews";
import React, { useState } from 'react';
import SuperGallery from "./pages/SuperGallery";
// import ChangeGallery from "./components/SetYear";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GalleryPage2007 from "./components/GalleryPages/2007";
import GalleryPage2008 from "./components/GalleryPages/2008";
import GalleryPage2009 from "./components/GalleryPages/2009";
import GalleryPage2010 from "./components/GalleryPages/2010";
import GalleryPage2011 from "./components/GalleryPages/2011";
import GalleryPage2012 from "./components/GalleryPages/2012";
import GalleryPage2013 from "./components/GalleryPages/2013";
import GalleryPage2014 from "./components/GalleryPages/2014";
import GalleryPage2015 from "./components/GalleryPages/2015";
import GalleryPage2016 from "./components/GalleryPages/2016";
import GalleryPage2017 from "./components/GalleryPages/2017";
import GalleryPage2018 from "./components/GalleryPages/2018";
import GalleryPage2019 from "./components/GalleryPages/2019";
import GalleryPage2020 from "./components/GalleryPages/2020";
import GalleryPage2021 from "./components/GalleryPages/2021";
import GalleryPage2022 from "./components/GalleryPages/2022";


function App() {

  
  // export function changeGallery(x) {
  //   const [ year, setYear ] = useState(2022)
  //   setYear(x);
  //   console.log(x);

  // }

  return (
    <div className="App">
      <Router>
        {/* <Navbar /> */}
        <Switch>
          <Route exact path="/">
            {/* <Banner /> */}
            <Home />
            <Menu />
            <About />
            <Contact />
          </Route>
        </Switch>
        <Switch>
          <Route path="/gallery" exact component={SuperGallery} />
          <Route path="/oldnews" exact component={OldNews} />
          {/* <Route path={year} exact component={"GalleryList" + year} /> */}
          <Route path="/2022" exact component={GalleryPage2022} />
          <Route path="/2021" exact component={GalleryPage2021} />
          <Route path="/2020" exact component={GalleryPage2020} />
          <Route path="/2019" exact component={GalleryPage2019} />
          <Route path="/2018" exact component={GalleryPage2018} />
          <Route path="/2017" exact component={GalleryPage2017} />
          <Route path="/2016" exact component={GalleryPage2016} />
          <Route path="/2015" exact component={GalleryPage2015} />
          <Route path="/2014" exact component={GalleryPage2014} />
          <Route path="/2013" exact component={GalleryPage2013} />
          <Route path="/2012" exact component={GalleryPage2012} />
          <Route path="/2011" exact component={GalleryPage2011} />
          <Route path="/2010" exact component={GalleryPage2010} />
          <Route path="/2009" exact component={GalleryPage2009} />
          <Route path="/2008" exact component={GalleryPage2008} />
          <Route path="/2007" exact component={GalleryPage2007} />
        </Switch> 
        {/* <Route path="/" exact page={Home} />
        <Route path="/" exact component={Menu} />
        <Route path="/" exact component={About} />
      <Route path="/" exact component={Contact} /> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
