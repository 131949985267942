const Data = `"Day of the Dolos" oil on wood, 36"x24", 2022
"Ash Can" oil on canvas, 36"x24", 2022
"Seven Day Weekend" oil on canvas, 36"x36", 2022
"The Glory Clouds" oil on canvas, 49"x49", 2021
"The Circular Ruins" oil on canvas, 43"x48", 2021
"Hand of Doom" oil on wood, 30"x40", 2020
"I Am The Wheel" oil on canvas, 36"x48", 2020
"That Tweeker Lady Just Saved the Planet" oil on canvas, 30"x48", 2020
"Mister Tambourine Man" oil on canvas, 30"x40", 2020
"The Spirit World, Revisited" oil on canvas 4'x5', 2019
"Station of the Cross" oil on wood, 16"x16", 2019
"Leisure Time" oil on wood, 16"x20", 2019
"Triangle Motel" oil on canvas, 30"x48", 2018
"Dancers Den" oil on canvas, 36"x48", 2018
"Weird Scenes Inside the Gold Mine" oil on wood, 30"x40", 2017
"The Dark Side" oil on wood, 30"x40", 2017
"Liquor and Drugs" oil on wood, 30"x40", 2016
"Trilogy of Terror" oil on wood, 30"x40", 2015
"Ride it Out" oil on wood, 30"x40", 2015
"Fragile" oil on wood, 30"x40", 2014
"Cul-de-sac" oil on canvas, 4'x3', 2014
"The Cosmopolitans" oil on wood, 40"x30", 2013
"Meth Mouth 2" oil on canvas, 30"x40", 2013
"Lords of Myrtletowne" oil on canvas, 24"x36", 2011
"Integratron" oil on canvas, 40"x30", 2010
"Slaying the Beast" oil on canvas, 40"x30", 2010
"Return to Eden, part 3", oil on canvas 24"x30", 2010
"Meth Mouth #3: The Deadly Spawn!" oil on canvas 30"x40", 2009
"Jesus Was a Capricorn", oil on canvas, 16"x20", 2008
"Meth Mouth" oil on canvas, 4'x3', 2007
"Saucy Wings" Oil 9"X12", 2005`;
export default Data;