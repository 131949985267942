import React from "react";
import { Link } from "react-router-dom";
import { SuperGalleryList } from "../helpers/SuperGalleryList";
import OldNewsItem from "../components/OldNewsItem";
import "../styles/OldNews.css";
import { data } from "../helpers/MediaText.js";
import  Data  from "../helpers/text.js";

function SuperGallery() {


  var foo = Data.split("\n");
      // console.log(foo[2]);

    const cache = {};

    function importAll(r) {
        r.keys().forEach((key) => (cache[key] = r(key)));
    }
    // Note from the docs -> Warning: The arguments passed to require.context must be literals!
    importAll(require.context("../helpers/media", false, /\.(png|jpe?g|svg)$/));
    
    const images = Object.entries(cache).map(module => module[1].default);
    


  return (
    <div className="oldNews" id="oldNews">

          <div>
          <Link to="/"> Jesse Wiedel </Link>
          </div>
      <h1 className="oldNewsTitle">Super Gallery</h1>
      {/* <div className="oldNewsList">
        {SuperGalleryList.map((oldNewsItem, key) => {
          return (
            <OldNewsItem
              key={key}
              linkTo={oldNewsItem.linkTo}
              image={oldNewsItem.image}
              name={oldNewsItem.name}
              description={oldNewsItem.description}
              
            />
          );
        })}
      </div> */}

      {/* <p>Media Page..</p>
      {foo.map((text, index) => (
        <p key={index}>
          {text}
        </p> 
      ))} */}
        
{images.map((image, index2) => (


  <div className="SuperGallery" >
    <img key={index2} src={image} />
    {/* <p>text</p> */}
        <p>
          {foo[index2]}
          </p>
        
    </div>

))}

      <button onClick={() => window.scrollTo(0,0)}> Top </button>
    </div>
  );
}

export default SuperGallery;
