import Pepperoni from "../../assets/studio424.jpg";
import Margherita from "../../assets/rpc.jpg";
import PedroTechSpecial from "../../assets/cdc.jpg";
import Vegan from "../../assets/vegan.jpg";
import Pineapple from "../../assets/pineapple.jpg";
import Expensive from "../../assets/expensive.jpg";

export const GalleryList2018 = [
  {
    name: "2022",
    image: Pepperoni,
    // linkTo: "https://catchitquickjuggling.com/",
    price: 15.99,
    // description: "Make a good first impression.  All sites have zero ads, so potential clients will stay focused on your content."  
  },
  {
    name: "2021",
    image: Margherita,
    linkTo: "https://www.reddingcooppreschool.org/",
    price: 11.99,
    description: "All sites are desktop and mobile friendly for ease of access on all devices."  
  },
  {
    name: "2020",
    image: PedroTechSpecial,
    linkTo: "https://cellardoorcider.com/index.html",
    price: 256.53,
    description: "Set up products for online purchases that can be shipped or allow customers to skip the lines on pickup."  
  },
  {
    name: "2019",
    image: Vegan,
    price: 17.99,
    description: "jjell adefe ffffes fjekek eke"  
  },
  {
    name: "2018",
    image: Pineapple,
    price: 4.99,
    description: "jjell adefe ffffes fjekek eke"  
  },
  // {
  //   name: "Very Expensive Pizza",
  //   image: Expensive,
  //   price: 1997.99,
  //   description: "jjell adefe ffffes fjekek eke"  
  // },
];
