import React from "react";
import { Link } from "react-router-dom";
import { GalleryList } from "../helpers/GalleryList";
import GalleryItem from "../components/GalleryItem";
import "../styles/Gallery.css";
import Navbar from "../components/Navbar";




function Gallery() {
  return (
    <div className="gallery" id="gallery">
        <div 
        // className="topStatic"
        >
          <div>
          <Link to="/"> Jesse Wiedel </Link>
          </div>
        </div>
      <h1 className="galleryTitle">Gallery</h1>
      <div className="galleryList">
        {GalleryList.map((galleryItem, key) => {
          return (
            <GalleryItem
              key={key}
              linkTo={galleryItem.linkTo}
              image={galleryItem.image}
              name={galleryItem.name}
              description={galleryItem.description}
              // price={menuItem.price}
            />
          );
        })}
      </div>
      <button onClick={() => window.scrollTo(0,0)}> Top </button>
    </div>
  );
}

export default Gallery;
