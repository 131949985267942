import React, { Component } from "react";
import Wave from "react-wavify";
import {HashLink as Link} from 'react-router-hash-link';
import BannerImage from "../assets/AshCan.jpg";
import "../styles/Home.css";


function Home() {
  
  return (
    <div className="topBottom">
      <div className="topStatic">
        <div>
        <Link smooth to="#menu"> News </Link>
        <Link to="/gallery"> Gallery </Link>
        <Link smooth to="#about"> About </Link>
        <Link smooth to="#contact"> Contact </Link>
        </div>
        <div>
          JESSE WIEDEL
        </div>
      </div>
      <div className="home" id="home" style={{ backgroundImage: `url(${BannerImage})` }}>
        
        <div className="headerContainer">
          <h2> Jesse Wiedel </h2>
        </div>
      </div>
      <div className="botStatic">
        <Link smooth to="#menu"> News </Link>
        <Link to="/gallery"> Gallery </Link>
        <Link smooth to="#about"> About </Link>
        <Link smooth to="#contact"> Contact </Link>
      </div>
      
    </div>


  );
}


export default Home;
